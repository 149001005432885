"use client";

import clsx from "clsx";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This is the only place where we use NextLink
import NextLink from "next/link";
import { forwardRef, useMemo } from "react";
import type { ForwardedRef, HTMLProps, ReactNode } from "react";
export type LinkProps = HTMLProps<HTMLAnchorElement> & {
  readonly className?: string;
  readonly children: ReactNode;
  readonly href: string;
  readonly tabIndex?: number;
  readonly openInNewTab?: "determineFromSource" | boolean;
  readonly testId?: string;
  readonly scroll?: boolean;
  readonly position?: string;
  readonly variant?: "inherit" | "inverse" | "primary" | "secondary" | null;
};

// Please refer to the the README file to read about the reasoning behind this component.

// eslint-disable-next-line @typescript-eslint/naming-convention -- components ought to be capitalized
export const Link = forwardRef(({
  children,
  className,
  href,
  onKeyDown,
  onMouseDown,
  openInNewTab = false,
  position,
  scroll = true,
  // Next default: shttps://nextjs.org/docs/app/api-reference/components/link#scroll
  tabIndex = 0,
  testId,
  variant = null
}: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const target = useMemo(() => {
    if (openInNewTab === "determineFromSource") {
      return /^(http|https):\/\//u.test(href) ? "_blank" : undefined;
    }
    return openInNewTab ? "_blank" : undefined;
  }, [href, openInNewTab]);
  return <NextLink className={clsx("hyphens-manual break-words transition-colors", variant === "primary" && "text-primary hover:underline", variant === "secondary" && "text-secondary underline hover:no-underline", variant === "inherit" && "text-inherit underline hover:text-black", variant === "inverse" && "footer-link", className)} data-k5a-pos={position} data-testid={testId}
  /* https://stackoverflow.com/questions/66821351/nextjs-error-message-failed-prop-type-the-prop-href-expects-a-string-or-o */ href={`${href}`} onKeyDown={onKeyDown} onMouseDown={onMouseDown} prefetch={false} ref={ref} scroll={scroll} tabIndex={tabIndex} target={target}>
        {children}
      </NextLink>;
});